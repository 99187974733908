import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Text
} from '@chakra-ui/react';
import Link from 'next/link';
import { getPageInfo } from '@/config/pages';

export type PcSideSearchLinkProps = {}

export const PcSideSearchLink = ({ }: PcSideSearchLinkProps) => {
  return (
    <Link
      href={getPageInfo('studio', {}).href}
      passHref={true}
    >
      <Box
        as={'a'}
        display={'block'}
        w={14}
        h={60}
        bg={'brand.500'}
        color={'white'}
        fontWeight={'bold'}
        textStyle={'h5'}
        letterSpacing={'3px'}
        css={{ writingMode: 'vertical-rl' }}
        textAlign={'center'}
        position={'fixed'}
        top={'250px'}
        right={0}
        zIndex={5}
        cursor={'pointer'}
        pr={2}
        borderTopLeftRadius={15}
        borderBottomLeftRadius={15}
      >
        <Text>
          <SearchIcon
            mr={3}
            mb={2}
          />
          店舗を検索する
        </Text>
      </Box>
    </Link>
  );
};
