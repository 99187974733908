import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react';
import { Image } from '@/components/Image';

export type SpHamburgerMenuItemWithIconProps = {
  icon?: string;
  altText: string;
  color?: string;
  onClose(): void;
}

export const SpHamburgerMenuItemWithIcon = ({
  icon,
  altText,
  color = '#73BA1A',
  onClose
}: SpHamburgerMenuItemWithIconProps) => {

  const textColor = altText === 'Re.Ra.Ku PAY' ? '#009E96' : color;

  return (
    <Box
      width={'100%'}
      textAlign={'left'}
      height={'56px'}
      lineHeight={'24px'}
      color={textColor}
      bg={'white'}
      _first={{ mt: 3 }}
      _last={{ mb: 3 }}
      cursor={'pointer'}
      onClick={onClose}
    >
      <Flex>
        <Flex
          alignItems={'center'}
        >
          {icon &&
            <Box
              position={'relative'}
              width={8}
              height={8}
              mt={1}
            >
              <Image
                src={icon}
                alt={altText}
                layout={'fill'}
                objectFit={'contain'}
              />
            </Box>}
          <Text
            padding={'1rem 0 0.75rem 0.75rem'}
            textStyle={'h5'}
            fontWeight={'bold'}
          >
            {altText}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
