import {
  ExternalLinkIcon
} from '@chakra-ui/icons';
import {
  HStack,
  Text,
  Link as ChakraLink,
  TextProps
} from '@chakra-ui/react';
import Link from 'next/link';
import { ReturnPageInfo } from '@/config/pages';

export type SpFooterLinkProps = {
  pageLink: ReturnPageInfo;
}

const LinkStyle: TextProps = {
  textStyle: 'h7',
  cursor: 'pointer',
  minH: '30px',
  pt: 1,
  pl: 3,
  borderLeft: '2px solid'
};

export const SpFooterLink = ({
  pageLink
}: SpFooterLinkProps) => {
  return (
    <>
      {pageLink.external ? (
        <ChakraLink
          href={pageLink.href}
          target={'_blank'}
          _hover={{
            textDecoration: 'none',
          }}
          width={'calc(100%/2)'}
        >
          <HStack
            justifyContent={'space-between'}
          >
            <Text
              {...LinkStyle}
            >
              {pageLink.name}
              <ExternalLinkIcon
                ml={1}
                mb={1}
              />
            </Text>
          </HStack>
        </ChakraLink>
      ) : (
        <Link
          href={pageLink.href}
          passHref={true}
        >
          <Text
            as={'a'}
            width={'calc(100%/2)'}
            {...LinkStyle}
          >
            {pageLink.name}
          </Text>
        </Link>
      )}
    </>
  );
};
