import {
  Box,
  BoxProps,
  Flex,
  Link as ChakraLink
} from '@chakra-ui/react';
import Link from 'next/link';
import {
  memo,
} from 'react';
import { Image } from '@/components/Image';
import { PcNavigationMenu } from '@/components/Navbar/Pc/PcNavigationMenu';
import { SpNavigationMenu } from '@/components/Navbar/Sp/SpNavigationMenu';
import { SITE_NAME } from '@/config/applicationConfig';
import { useCustomerLogout } from '@/hooks/useCustomerLogout';

interface NavbarProps extends BoxProps {}

export const navbarHeight = 56;

export const Navbar = memo(({ ...boxProps }: NavbarProps) => {
  const { logout: handleLogout } = useCustomerLogout();

  return (
    <Box
      as={'header'}
      role={'banner'}
      position={'sticky'}
      top={0}
      zIndex={'overlay'}
      {...boxProps}
    >
      <Box>
        <Flex
          bg={'white'}
          color={'gray.600'}
          minH={`${navbarHeight}px`}
          py={{ base: 2 }}
          px={{ base: 4 }}
          align={'center'}
        >
          <Flex
            flex={1}
            justify={'flex-start'}
          >
            <Link
              href={'/'}
              passHref={true}
            >
              <ChakraLink>
                <Image
                  src={'/images/logo/reraku_group_logo.png'}
                  width={280}
                  height={50}
                  alt={SITE_NAME}
                />
              </ChakraLink>
            </Link>
          </Flex>
          <PcNavigationMenu/>
          <SpNavigationMenu
            onLogout={handleLogout}
          />
        </Flex>
      </Box>
    </Box>
  );
});
Navbar.displayName = 'Navbar';
