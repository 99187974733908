import {
  ArrowForwardIcon,
  ExternalLinkIcon
} from '@chakra-ui/icons';
import {
  Box,
  Text,
  Flex,
  Link as ChakraLink,
  TextProps,
} from '@chakra-ui/react';
import Link from 'next/link';
import { ReturnPageInfo } from '@/config/pages';

type SpHamburgerMenuItemProps = {
  pageLink: ReturnPageInfo;
  onClose(): void;
}

const LinkStyle: TextProps = {
  display: 'flex',
  height: '56px',
  lineHeight: '24px',
  bg: 'white',
  color: '#58595B'
};

export const SpHamburgerMenuItem = ({ pageLink, onClose }: SpHamburgerMenuItemProps) => {
  return (
    <Box
      _notLast={{
        borderBottom: '1px solid',
        borderColor: 'gray.200'
      }}
    >
      {pageLink.external ? (
        <ChakraLink
          href={pageLink.href}
          _hover={{ textDecoration: 'none' }}
          target={'_blank'}
          _focus={{ boxShadow: 'none' }}
        >
          <Box
            onClick={onClose}
            {...LinkStyle}
          >
            <Text
              padding={'1rem 0 0.75rem 0'}
              textStyle={'h5'}
            >
              {pageLink.name}
              <ExternalLinkIcon
                ml={1}
                mb={1}
              />
            </Text>
          </Box>
        </ChakraLink>
      ) : (
        <Link
          href={pageLink.href}
          passHref={true}
        >
          <ChakraLink
            _hover={{
              textDecoration: 'none'
            }}
            _focus={{ boxShadow: 'none' }}
          >
            <Box
              onClick={onClose}
              {...LinkStyle}
            >
              <Text
                padding={'1rem 0 0.75rem 0'}
                textStyle={'h5'}
              >
                {pageLink.name}
              </Text>
              <Flex
                flex={1}
                mr={3}
                justify={'flex-end'}
              >
                <ArrowForwardIcon
                  color={'#58595B'}
                  marginTop={'1rem'}
                  boxSize={5}
                />
              </Flex>
            </Box>
          </ChakraLink>
        </Link>
      )}
    </Box>
  );
};
