import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Text,
  Link as ChakraLink,
  TextProps
} from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReturnPageInfo } from '@/config/pages';

type PcNavigationMenuItemProps = {
  pageLink: ReturnPageInfo;
};

const LinkStyle: TextProps = {
  px: { base: '0.5rem', xl: '1rem' },
  pt: '1rem',
  pb: '0.75rem',
  textStyle: { lg: 'h10', xl: 'h6' }
};

export const PcNavigationMenuItem = ({ pageLink }: PcNavigationMenuItemProps) => {
  const router = useRouter();
  const isActive = router.pathname.startsWith(pageLink.href);

  return (
    <Box
      cursor={'pointer'}
      color={isActive ? 'brand.500' : 'brandSecondary.500'}
      borderBottom={isActive ? '3px solid #82ae40' : '3px solid white'}
      _hover={{
        color: 'brand.500',
        borderBottom: '3px solid #82ae40'
      }}
    >
      {pageLink.external ? (
        <ChakraLink
          href={pageLink.href}
          target={'_blank'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text
            {...LinkStyle}
          >
            {pageLink.name}
            <ExternalLinkIcon
              ml={1}
              mb={1}
            />
          </Text>
        </ChakraLink>
      ) : (
        <Link
          href={pageLink.href}
          passHref={true}
        >
          <Text
            as={'a'}
            display={'block'}
            {...LinkStyle}
          >
            {pageLink.name}
          </Text>
        </Link>
      )}
    </Box>
  );
};
